import React from 'react';
import { graphql } from "gatsby";
import Home from './home';
import Layout from '../layout/layout';

export const pageQuery = graphql`
  {
    WordPress {
      pageBy(id: "cG9zdDoyNw==") {
        title
        acfDemoFields100 {
          templateHomeContent {
            __typename
            ... on WordPress_Page_Acfdemofields100_TemplateHomeContent_HomePageBanner {
              homePageBanner {
                homePageBannerImage {
                  sourceUrl
                }
              }
            }
            ... on WordPress_Page_Acfdemofields100_TemplateHomeContent_UmxSuiteSection {
              umxSuiteSectionTitle
              umxSuiteSectionDescription
              umxImageForContent{
                sourceUrl
              }
              umxSectionCenterLogoText
              umxSectionCenterLogo {
                id
                sourceUrl
              }
            }
            ... on WordPress_Page_Acfdemofields100_TemplateHomeContent_HomePageLeftRightSection {
              leftRightSectionBackground
              leftRightSectionButton {
                leftRightSectionButtonLink {
                  __typename
                }
                leftRightSectionButtonTitle
              }
              leftRightSectionDescription
              leftRightSectionIcon {
                leftRightSectionIconColor1
                leftRightSectionIconColor2
                leftRightSectionIconName
                leftRightSectionIconImage {
                  sourceUrl
                }
              }
              leftRightSectionImage {
                sourceUrl
              }
              leftRightSectionImagePosition
              leftRightSectionTitle
              leftRightSectionImageAlignment
              leftRightSectionGooglePlayStore
              leftRightSectionAppleStore
            }
          }
        }
      }
    }
}`;

const Index = ({ data }) => {

  return (
    <Layout headerColor="header-home" seoTitle={'UMX University'} seoDes={"x-umusic homepage"}>
        <Home data={data} path="/" />
    </Layout>
  );
};

export default Index;
